<template>
	<div id="add-to-home-container" v-if="showAddToHomeButton">
		<i class="fas fa-times" @click="hideBanner"></i>
		<img src="../assets/badge.svg">
		<span>
			<span>{{ title }}</span>
			<span>brydg.com</span>
		</span>
		<button id="add-to-home" @click="addToHome">Install</button>
	</div>
</template>

<script>
export default {
  name: 'InstallBanner',
  data() {
    return {
			addToHomeScreenPrompt: null,
			showAddToHomeButton: false,
			// title: 'BRYDG (Peter Matthews)',
			// title: 'BRYDG BDM (Aysan Ozel)',
			// title: 'BRYDG BDM (Pedja Savovic)',
			title: 'BRYDG BDM (Laurent Chocron)',
			// title: 'BRYDG BDM (Jack Hodgkin)'
    }
  },
	methods: {
		addToHome() {
      // hide our user interface that shows our A2HS button
      this.showAddToHomeButton = false;
      // Add event to variable
      let deferredPrompt = this.addToHomeScreenPrompt;
      // Show the prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } 
        else {
          console.log('User dismissed the A2HS prompt');
        }
        deferredPrompt = null;
      });
    },
    hideBanner() {
      this.showAddToHomeButton = false;
    }
	},
	mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.addToHomeScreenPrompt = e;
      // Update UI to notify the user they can add to home screen
      this.showAddToHomeButton = true;
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#add-to-home-container {
  width: 100%;
  max-width: 1024px;
  padding: 1rem;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#add-to-home-container > i {
  margin-right: .5rem;
  color: rgba(0, 0, 0, 0.5);
}
#add-to-home-container > span {
  margin-right: auto;
  margin-left: 1rem;
}
#add-to-home-container > span > span {
  display: block;
}
#add-to-home-container > span > span:first-of-type {
  font-weight: 700;
}
#add-to-home {
  border: none;
  background: #000;
  color: #fff;
  padding: 1rem 2rem;
}
@media only screen and (min-width: 768px) {
	/* TO DO */
  #add-to-home-container {
		width: 100%;
		max-width: 1024px;
		padding: 1rem;
		background: rgba(255, 255, 255, 1);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
</style>